import Header from '../../Components/Header/Header';
import Hero from '../../Components/Hero/Hero';
import QuestList from '../../Components/QuestList/QuestList';
import Qualitat from '../../Components/Qualitat/Qualitat';
import Footer from '../../Components/Footer/Footer';
import { useEffect } from 'react';
import s from './Homepage.module.css';
import { Link } from 'react-scroll';

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Hero />
      <div className={s.descContainer}>
        <h1>
          Ласкаво просимо в Outlast Quest — мережу неймовірних квест-кімнат в
          Одесі!
        </h1>
        <div className={s.description}>
          <p>
            Вітаємо вас у Outlast Quest, де кожен крок веде вас в світ
            незабутніх пригод і справжнього страху. Наша мережа квест-кімнат в
            Одесі пропонує чотири унікальні ігрові сценарії, які стануть
            випробуванням для вашої сміливості та логічного мислення. Зануртеся
            в атмосферу жаху і хвилюючих емоцій, розв'язуючи головоломки і
            долаючи перешкоди, які ми підготували для вас.
          </p>
          <p>
            Відчуйте напругу і адреналін в квест-кімнаті "Прокляття монахині",
            де кожен звук і тінь нагадують про давнє зле прокляття. Випробуйте
            свою стійкість у "Цирку жахів", де ви станете частиною страшного
            шоу, що тримає вас в постійній напрузі. Пориньте в атмосферу
            непередбачуваних подій у "Поворот не туди", де кожен поворот може
            стати фатальним. І нарешті, відкрийте таємниці "Пікової дами", де
            страх і загадка сплітаються в одне ціле, поглинаючи вас у світ
            загадкових подій.
          </p>
          <p>
            Outlast Quest — це не просто квести, це виклик для вашої команди,
            яка повинна проявити свою рішучість, розум і здатність до швидкої
            адаптації. Кожна гра створена для того, щоб перенести вас в
            абсолютно інший світ, де адреналін і задоволення від досягнення мети
            переплітаються в неперевершеному досвіді.
          </p>
          <p>
            Приходьте в Outlast Quest і дізнайтеся, що таке справжні пригоди і
            жахи! Забронюйте своє місце вже сьогодні і розпочніть захоплюючу
            подорож у світ, де кожен момент тримає вас на межі можливого!
          </p>
        </div>
        <div className={s.strongCont}>
          <p className={s.reservDesc}>
            <strong>
              Приєднуйтесь до нас і переживіть незабутні моменти страху і
              захоплення!
            </strong>
          </p>
          <Link to="questi" smooth={true} duration={400}>
            <button className={s.descLink}>Забронювати зараз</button>
          </Link>
        </div>
      </div>
      <Qualitat />
      <QuestList />
      <Footer />
    </div>
  );
}
